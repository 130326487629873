
//mixins para el layout
@mixin antesTablet {
    @media (max-width: 769px) {
        @content;
    }
}
@mixin antesDesktop {
    @media ( max-width: 1200px ){
        @content;
    }
}
@mixin antesTelefono{
    @media(max-width: 280px){
        @content;
    }
}
@mixin telefono {
    @media (min-width: 480px) {
        @content;
    }
}
@mixin tablet{
    @media (min-width: 768px){
        @content;
    }
}
@mixin entreTabletDesktop{
    @media( min-width: 1080px ){
        @content;
    }
}
@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin pantallasGrandes{
    @media (min-width:1600px){
        @content
    }
}


@mixin entreTablet{
    @media(min-width: 600px) and (max-width: 959px){
        @content
    }
}