
@import '_variables'; 
@import '_mixins';

img{
    width: 100%;
}


.contenedor{
    max-width: 120rem;
    margin: 0 auto;
}

.encabezado{
    width: 100%;
    text-align: center;
}

.encabezado{
    font-family: $fuenteTitulosRegular;
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.encabezado-bc{
    background-color: $colorPrincipal;
    color: $blanco-hover;
    margin: 0;
    padding: 2rem 0;
}

.boton-p{
    display: block;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    text-decoration: none;
    font-size: 1.5rem;
    font-family: $fuenteSecundaria;
    font-weight: 300;
    background: transparent;
    color: inherit;
    border: none;
    padding: 1rem 5rem;
    cursor: pointer;
    outline: inherit;
    border-radius: 5px;

    @include tablet{
        width: auto;
        font-size: 1.7rem;
    }
}

.boton-p-primario{
    background-color: $colorPrincipal;
    color: $blanco;
    transition: ease-in-out all 0.3s;
    &:hover{
        color: $blanco;
        background-color: $colorPrincipal-hover
    }
}

.boton-p-outline{
    text-align: center;
    background-color: transparent;
    color: $blanco;
    border: 1px solid $blanco;
    font-weight: 300;
    padding: 0.5rem 1rem;
    transition: ease-in-out all 0.3s;
    &:hover{
        color: $blanco;
        background-color: $colorSecundario;
        border-color: $colorSecundario; 
    }
}


/*FORMULARIOS*/

.formulario{

    
}