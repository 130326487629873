

/*COLORES*/

$colorPrincipal         : #191769;
$colorPrincipal-hover   : #211f83;
$colorSecundario        : #2b8bdf;
$colorSecundario-hover  : #236fb1;

$colorPrincipalTransparent :  #18176948;

$blanco        : rgb(241, 241, 241);
$blanco-hover  : #f7f2f2;

$gris          : rgb(218, 216, 216);
$gris-hover  : #c1c1c1;


/*Fuentes*/

$fuenteTitulosRegular   : 'Oswald-Regular';
$fuenteTitulosMedium    : 'Oswald-Medium';
$fuenteTitulosBold      : 'Oswald-Bold';

$fuenteSecundaria       : 'OpenSans';
$fuenteSecundariaBold   : 'OpenSans-Bold';
